const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6918298', space_js: '//acjfpbd.cjdigit.com/source/v/production/q_pyzs/f_ys.js' }];
const DIALOG = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6918299', space_js: '//acjfpbd.cjdigit.com/production/w/r/production/qz/static/atgz/source/z.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6918300', space_js: '//acjfpbd.cjdigit.com/site/fa_zij/resource/cs_g/g.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6918301', space_js: '//acjfpbd.cjdigit.com/common/g_bajk/static/dt_h/k.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6918302', space_js: '//acjfpbd.cjdigit.com/site/h/production/cbk/static/leu-i/r.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6918303', space_js: '//acjfpbd.cjdigit.com/site/id/openjs/c/l/production/mfvjv.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_dialog: DIALOG,
    Home_banner: BANNER,
    Main_native: NATIVE_1,
    Classroom_native: NATIVE_2,
    Wikipedia_cat_native: NATIVE_1,
    Wikipedia_dog_native: NATIVE_2,
    Wikipedia_other_native: NATIVE_3,
    ConstellationList_banner: BANNER,
    ConstellationList_native: NATIVE_1,
    ArticleDetail_inter: INTER,
    ArticleDetail_banner: BANNER,
    ArticleDetail_native: NATIVE_1,
    PetDetail_inter: INTER,
    PetDetail_banner: BANNER,
    PetDetail_native: NATIVE_1,
    Mood_dialog: DIALOG,
};
